export default {
  auth: {
    login: '/admin/login',
    logout: '/admin/logout',
    changePassword: 'admin/change-password',
    forgotPassword: 'admin/forgot-password',
    resetPassword: 'admin/reset-password',
  },
  user: {
    getUsers: 'admin/get-users',
    create: 'admin/create-user',
    update: 'admin/update-user',
    changeStatusUser: 'admin/change-status-user',
    delete: 'admin/delete-user',
  },
  common: {
    districts: 'common/districts',
    executives: 'common/executives',
  },
  dashboard: {
    getDashboardData: 'admin/get-dashboard',
    getPaymentDetails: 'admin/get-payment-details',
  },
  members: {
    getAllMembers: 'admin/get-all-members',
    createMember: 'admin/create-member',
    updateMember: 'admin/update-member',
    uploadDocuments: 'admin/upload-documents',
    getMembers: 'admin/get-members',
    getSingleMember: 'admin/get-members',
    deleteMember: 'admin/delete-member',
    paymentDone: 'admin/payment-done-member',
    exportMembers: 'admin/export-members',
    getComplaints: 'admin/get-complaints',
    updateStatus: 'admin/update-member-status',
    updateProfilePhoto: 'admin/update-member-profile-picture',
  },
  executives: {
    getExecutives: 'admin/get-executives',
    createExecutive: 'admin/create-executive',
    updateExecutive: 'admin/update-executive',
    changeStatusExecutive: 'admin/change-status-executive',
  },
  loyalty: {
    getRedeemRequests: 'admin/get-redeem-requests',
    updateRedeemRequests: 'admin/update-redeem-requests',
    exportRedeemRequests: 'admin/export-redeem-requests',
  },
  sticker: {
    getStickers: 'admin/get-stickers',
    createSticker: 'admin/create-sticker',
    updateSticker: 'admin/update-sticker',
    delete: 'admin/delete-sticker',
    feed: 'admin/feed-points',
    getMembersPoints: 'admin/members-points',
    updateMinimumValue: 'admin/update-points',
    getMinimumValue: 'admin/get-minimum-points',
  },
};
