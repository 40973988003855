import store from '@/store';
// import notificationsUtility from '@/utilities/notifications-utility';
import Vue from 'vue';
import VueRouter from 'vue-router';
import authModule from './auth-module';
import dashboardModule from './dashbaord';
import memberModule from './members';

import PageNotFound from '../views/Error404.vue';
// import i18n from '@/plugins/i18n';

Vue.use(VueRouter);

const routes = [
  ...authModule,
  ...dashboardModule,
  ...memberModule,

  { path: '*', component: PageNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('token');

  if (!to.meta.publicPage) {
    if (!isLoggedIn) {
      if (from.name != 'login') {
        return next({ name: 'login' });
      }
    } else {
      // Access the current admin type from your application state or Vuex store
      const currentUserAdminType = store.state.auth.user.admin_type; // Replace with your actual admin type getter

      // Check if the admin type is allowed for the route
      if (
        to.meta.adminType &&
        !to.meta.adminType.includes(currentUserAdminType)
      ) {
        // Redirect to a different page or show an error message
        next('/unauthorized'); // Replace with the appropriate redirect or error handling
      } else {
        next(); // Allow navigation to the route
      }
    }
  } else {
    if (
      isLoggedIn &&
      ['login', 'register', 'forgot-password'].includes(to.name)
    ) {
      window.location.replace('/');
    }
  }
  return next();
});

export default router;
