export default [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/index.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin', 'accounts'],
    },
  },
  {
    path: '/executives',
    name: 'executives',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ '../views/dashboard/Executives.vue'
      );
    },
    meta: {
      publicPage: false,
      adminType: ['admin', 's_admin'],
    },
  },
];
